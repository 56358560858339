<template>
    <section-layout class="section-steps d-flex justify-content-center">
        <div class="section-steps__content -pt-4 -pt-sm-12 col-sm-20">
            <p class="section-steps__title heading-48 text-center">Etapy programu:</p>

            <div class="section-steps__items d-flex flex-column mt-4">
                <div class="section-steps__item">
                    <p class="section-steps__title heading-32">ETAP I</p>
                    <p class="section-steps__subtitle heading-20">Od 15 października 2024 r. do 10 listopada 2024 r.</p>
                    <div>
                        <div class="section-steps__text text-16">
                            <p class="heading-16">Zgłoszenia utalentowanych uczniów przyjmowane są w czterech
                                kategoriach:
                            </p>
                            <ul class="-mb-0 d-flex flex-column list">
                                <li>sport</li>
                                <li>sztuka</li>
                                <li>nauka</li>
                                <li>zaangażowanie społeczne i ekologia</li>
                            </ul>
                            <p class="heading-16 form__pt-24">W każdej kategorii wyróżniamy dwie grupy:</p>
                            <ul class="-mb-0 d-flex flex-column list">
                                <li>uczniowie szkoły podstawowej (klasy IV-VIII) </li>
                                <li>uczniowie szkoły ponadpodstawowej (wszystkie klasy) </li>
                            </ul>
                            <p class="heading-16 form__pt-24 mb-1">Jak się zgłosić:</p>
                            <p>W terminie od 15 października 2024 r. do 10 listopada 2024 r. należy wypełnić formularz
                                aplikacyjny online<span v-if="stageStatus != 2">.</span><span v-if="stageStatus == 2">,
                                    dostępny <router-link :to="{ name: 'signUpStudent' }">TUTAJ</router-link></span></p>
                        </div>
                    </div>
                </div>

                <div class="section-steps__item">
                    <p class="section-steps__title heading-32">ETAP II</p>
                    <p class="section-steps__subtitle heading-20">10 grudnia 2024 r. do 6 stycznia 2025 r.</p>
                    <div class="mb-5">
                        <div class="section-steps__text text-16">
                            <p class="heading-16">Jury wybierze maksymalnie 80 najlepszych zgłoszeń w każdej z 4
                                kategorii
                                (po 40 uczniów z każdej grupy wiekowej).</p>
                            <p>Finaliści pierwszego etapu przygotują zadanie konkursowe — stworzą prezentację w formie
                                filmu
                                video, w której przedstawią siebie oraz to, w jaki sposób przyznane stypendium wspomoże
                                rozwój ich pasji i talentów.</p>
                            <p class="heading-16 -pt-2">Na tym etapie do zgłoszenia należy załączyć</p>
                            <ul class="-mb-0 d-flex flex-column list">
                                <li>świadectwo ucznia za rok szkolny 2023/2024, dyplomy i inne dokumenty potwierdzające
                                    osiągnięcia ucznia i jego zaangażowanie w działalność społeczną w latach 2023-2024,
                                </li>
                                <li>zgodę na przetwarzanie danych osobowych osób występujących w filmie (w tym na
                                    wykorzystanie wizerunku). </li>
                            </ul>
                        </div>
                    </div>
                    <p class="color-blue heading-24 mb-3">Pliki do pobrania:</p>
                    <div>
                        <div class="section-steps__link-items">
                            <div class="section-steps__link-item">
                                <p class="heading-18 color-blue">Regulamin programu „Enea Akademia Talentów” dla uczniów
                                </p>
                                <a href="Regulamin_Konkursu_AT_2024.10.15.pdf" target="_blank"
                                    class="button__red">Pobierz<img src="@/assets/download-btn.svg" alt=""
                                        class="button__download" /></a>
                            </div>
                            <div class="section-steps__link-item">
                                <p class="heading-18 color-blue">Materiały informacyjne</p>
                                <a href="ENEA_AT_Materialy_Infromacyjne_2024.pdf" target="_blank"
                                    class="button__red">Pobierz<img src="@/assets/download-btn.svg" alt=""
                                        class="button__download" /></a>
                            </div>
                            <div class="section-steps__link-item" v-if="stageStatus == 4">
                                <p class="heading-18 color-blue">Załączniki do zgłoszenia w II etapie</p>
                                <a href="Zalaczniki_do_zgloszenia_II_Etap_Uczen.pdf" target="_blank"
                                    class="button__red">Pobierz<img src="@/assets/download-btn.svg" alt=""
                                        class="button__download" /></a>
                            </div>
                            <div class="section-steps__link-item" v-if="stageStatus == 4">
                                <p class="heading-18 color-blue">Instrukcja dla uczniów jak przygotować się do drugiego
                                    etapu</p>
                                <a href="ENEA_AT_2024_manual.pdf" target="_blank" class="button__red">Pobierz<img
                                        src="@/assets/download-btn.svg" alt="" class="button__download" /></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section-steps__item">
                    <p class="section-steps__title heading-32">ETAP III — GŁOSOWANIE</p>
                    <p class="section-steps__subtitle heading-20">Od 15 stycznia 2025 r. do 26 stycznia 2025 r.</p>
                    <div>
                        <div class="section-steps__text text-16">
                            <p class="heading-16 section-steps__pb-16">Na stronie Enei Akademii Talentów zostaną
                                stworzone
                                profile uczniów z prezentacją zadania konkursowego.</p>
                            <p class="text-16">Jury wybierze maksymalnie po 8 zwycięzców w każdej z 4 kategorii (po 4
                                uczniów w każdej kategorii w danej grupie wiekowej), którzy otrzymają stypendium.
                                Dodatkowo
                                internauci będą głosować na najciekawsze zgłoszenia. Uczniowie, którzy nie zostaną
                                wybrani
                                przez jury, a otrzymają najwyższą liczbę głosów w swojej kategorii, również zostaną
                                nagrodzeni i otrzymają stypendia.</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section-layout>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
            changeContent: true,
        };
    },
    methods: {
    },
    components: {
    },
    computed: {
        stageStatus() {
            return this.$store.state.stage
        }
    },
};
</script>
