<template>
    <section-layout class="section-patron">
        <div class="section-patron__content">
            <div class="container-fluid">
                <div class="d-flex justify-content-center section-patron__logos">
                    <h3>Patronaty honorowe</h3>
                </div>
                <div class="d-flex flex-row  align-items-center justify-content-center section-patron__logos">
                    <img height="106" class="section-patron__image" src="@/assets/patron.png" alt="Patron" />
                    <img height="106" class="section-patron__image" src="@/assets/ministerstwo_sportu_i_turystyki.png" alt="Patron" />
                    <img height="106" class="section-patron__image" src="@/assets/ministerstwo_kultury_i_dziedzictwa_narodowego.png" alt="Patron" />
                    <img height="106" class="section-patron__image" src="@/assets/ministerstwo_aktywow_panstwowych.png" alt="Patron" />
                </div>

                <!-- <p class="text-16 section-patron__title text-center fw-bold pb-3">Pod patronatem</p>
                <div class="container section-patron__logos align-items-center d-flex">
                    <img src="@/assets/img-patron1.png" alt="" class="section-patron__image"/>
                    <img src="@/assets/img-patron2.png" alt="" class="section-patron__image"/>
                    <img src="@/assets/img-patron3.png" alt="" class="section-patron__image"/>
                    <img src="@/assets/img-patron4.png" alt="" class="section-patron__image"/>
                </div> -->
            </div>
        </div>

    </section-layout>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
        };
    },
    methods: {
    },
    components: {
    },
    computed: {
    },
};
</script>
